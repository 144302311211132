// extracted by mini-css-extract-plugin
export var confirmApplicationButton = "style-module--confirm-application-button--vBqpo";
export var confirmApplicationText = "style-module--confirm-application-text--UuAcY";
export var congratulationsText = "style-module--congratulations-text--NeNji";
export var contentContainer = "style-module--content-container--pDCJk";
export var emailSentText = "style-module--email-sent-text--jttfx";
export var fadeIn = "style-module--fade-in--5i5nQ";
export var header = "style-module--header--uuIn-";
export var logoContainer = "style-module--logo-container--0JEkl";
export var lottieContainer = "style-module--lottie-container--ZCgnL";
export var mainContainer = "style-module--main-container--EuYEZ";
export var step = "style-module--step--f33C6";
export var stepsContainer = "style-module--steps-container--2piOh";
export var timer = "style-module--timer--ooyBq";
export var transition = "style-module--transition--bSvY8";
export var transitiona = "style-module--transitiona--YrUKT";
export var transitionb = "style-module--transitionb--kD8X+";