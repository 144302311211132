import React, { useState, useEffect } from "react";

import { Container, DatePicker, Icon } from "../../..";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import Toastify from "toastify-js";

import axios from "axios";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [loading, setLoading] = useState(true);
  const [enrollmentLink, setEnrollmentLink] = useState("");
  const params = JSON.parse(localStorage.getItem("surebridge_params"));
  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const plans = JSON.parse(localStorage.getItem("surebridge_plans"));
  const [isEnrolling, setIsEnrolling] = useState(false);

  const spouse = props?.spouse;
  const dependents = props?.applicants.dependents;
  const uniqueArray = (a) =>
    [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

  const oneTimeFees = uniqueArray(
    plans
      .map((plan) => plan?.fees["ONE_TIME"]?.fees_details)
      .filter((fee) => fee)
      .flat()
  );
  const monthlyFees = uniqueArray(
    plans
      .map((plan) => plan?.fees["MONTHLY"]?.fees_details)
      .filter((fee) => fee)
      .flat()
  );

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
      setLoading(false);
    });
  }, []);

  const clearLocalStorage = () => {
    plans.forEach((plan) => {
      localStorage.removeItem(
        `surebridge-selectedBenefitLevelsNoPeriod-${plan.id}`
      );
      localStorage.removeItem(
        `surebridge-selectedBenefitLevelsWithPeriod-${plan.id}`
      );
      plan?.general_info?.optional_riders?.forEach((rider) =>
        localStorage.removeItem(`surebridge-rider-${rider.name}-${plan.id}`)
      );
    });
  };

  const parsePlansToEnroll = () => {
    const handleIndividualPlansNoPeriod = (plan) => {
      const individualsLevels = JSON.parse(
        localStorage.getItem(
          `surebridge-selectedBenefitLevelsNoPeriod-${plan.id}`
        ) || "[]"
      );

      const dependents = individualsLevels.filter(
        (rider) => rider.applicant_type === "DEPENDENT"
      );

      const applicants = individualsLevels
        .map((level, ind) => {
          if (level.applicant_type !== "DEPENDENT") {
            return {
              applicant_id: ind + 1,
              rate: level?.selectedOption?.premium_amount,
              selected_benefit: level?.selectedOption,
            };
          }
        })
        .filter((applicant) => applicant);

      const dependentsApplicants = dependents.map((level, ind) => ({
        applicant_id: ind + 3,
        rate: level?.selectedOption?.premium_amount,
        selected_benefit: level?.selectedOption,
      }));

      return {
        key_code: plan?.general_info?.key_code,
        internal_name: plan?.general_info?.internal_name,
        has_fee: plan?.general_info?.has_fee,
        brochure_link: plan?.brochure_link?.value,
        applicants: [...applicants, ...dependentsApplicants],
        optional_riders: parseRidersToEnroll(plan),
      };
    };

    const handleFamilyPlansWithPeriod = (plan) => {
      const selectedFamilyPeriod = JSON.parse(
        localStorage.getItem(
          `surebridge-selectedBenefitLevelWithPeriod-${plan.id}`
        ) || "{}"
      );

      const options = {
        rate: selectedFamilyPeriod?.selectedPeriod?.premium_amount,
        selected_benefit: {
          ...selectedFamilyPeriod?.selectedPeriod,
          benefit_value: selectedFamilyPeriod?.selectedOption.benefit_value,
        },
      };

      const primaryApplicant = {
        applicant_id: 1,
        ...options,
      };

      const spouseApplicant = spouse
        ? {
            applicant_id: 2,
            ...options,
          }
        : null;

      const dependentsApplicants = dependents
        ? dependents.map((_, ind) => ({
            applicant_id: ind + 3,
            ...options,
          }))
        : null;

      return {
        key_code: plan?.general_info?.key_code,
        internal_name: plan?.general_info?.internal_name,
        has_fee: plan?.general_info?.has_fee,
        brochure_link: plan?.brochure_link?.value,
        applicants: [
          primaryApplicant,
          spouseApplicant,
          ...dependentsApplicants,
        ].filter((applicant) => applicant),
        optional_riders: parseRidersToEnroll(plan),
      };
    };

    const handleOnlyFamilyPlans = (plan) => {
      const primaryApplicant = {
        applicant_id: 1,
        rate: plan.premium.value,
      };

      const spouseApplicant = spouse
        ? {
            applicant_id: 2,
            rate: plan.premium.value,
          }
        : null;

      const dependentsApplicants = dependents
        ? dependents.map((_, ind) => ({
            applicant_id: ind + 3,
            rate: plan.premium.value,
          }))
        : null;

      return {
        key_code: plan?.general_info?.key_code,
        internal_name: plan?.general_info?.internal_name,
        has_fee: plan?.general_info?.has_fee,
        brochure_link: plan?.brochure_link?.value,
        applicants: [
          primaryApplicant,
          spouseApplicant,
          ...dependentsApplicants,
        ].filter((applicant) => applicant),
        optional_riders: parseRidersToEnroll(plan),
      };
    };

    const handleOnlyIndividualPlans = (plan) => {
      const primaryApplicant = {
        applicant_id: 1,
        rate: plan.general_info?.individual_rates?.find(
          (rate) => rate.applicant_type === "PRIMARY"
        )?.rate,
      };

      const spouseApplicant = spouse
        ? {
            applicant_id: 2,
            rate: plan.general_info?.individual_rates?.find(
              (rate) => rate.applicant_type === "SPOUSE"
            )?.rate,
          }
        : null;

      const dependentsApplicants = dependents
        ? dependents.map((dep, ind) => ({
            applicant_id: ind + 3,
            rate: plan.general_info?.individual_rates?.find(
              (rate) =>
                rate.applicant_type === "DEPENDENT" &&
                Number(dep.age) === Number(rate.age)
            )?.rate,
          }))
        : null;

      return {
        key_code: plan?.general_info?.key_code,
        internal_name: plan?.general_info?.internal_name,
        has_fee: plan?.general_info?.has_fee,
        brochure_link: plan?.brochure_link?.value,
        applicants: [
          primaryApplicant,
          spouseApplicant,
          ...dependentsApplicants,
        ].filter((applicant) => applicant),
        optional_riders: parseRidersToEnroll(plan),
      };
    };

    return plans.map((plan) => {
      if (
        plan?.general_info?.rate_type === "INDIVIDUAL" &&
        plan?.general_info?.benefit_level_period_type === "NO_PERIOD"
      ) {
        return handleIndividualPlansNoPeriod(plan);
      }
      if (
        plan?.general_info?.rate_type === "FAMILY" &&
        plan?.general_info?.benefit_level_period_type === "WITH_PERIOD"
      ) {
        return handleFamilyPlansWithPeriod(plan);
      }
      if (plan?.general_info?.rate_type === "FAMILY") {
        return handleOnlyFamilyPlans(plan);
      }
      if (plan?.general_info?.rate_type === "INDIVIDUAL") {
        return handleOnlyIndividualPlans(plan);
      }
    });
  };

  const parseRidersToEnroll = (plan) => {
    const handleIndividualRiders = (rider) => {
      const selectedRider = JSON.parse(
        localStorage.getItem(`surebridge-rider-${rider.name}-${plan.id}`)
      );

      if (!selectedRider) return null;

      const dependents = selectedRider.filter(
        (rider) => rider.applicant_type === "DEPENDENT"
      );

      const applicants = selectedRider
        .map((rider, ind) => {
          if (rider.applicant_type !== "DEPENDENT") {
            return {
              applicant_id: ind + 1,
              selected_benefit: rider.selectedOption,
            };
          }
        })
        .filter((applicant) => applicant);

      const dependentsApplicants = dependents.map((rider, ind) => ({
        applicant_id: ind + 3,
        selected_benefit: rider.selectedOption,
      }));

      return {
        name: rider?.name,
        plan_code: rider?.plan_code,
        rate_type: rider?.rate_type,
        rider_type: rider?.rider_type,
        applicants: [...applicants, ...dependentsApplicants],
      };
    };

    const handleFamilyRidersRequote = (rider) => {
      const selectedFamilyPeriod = JSON.parse(
        localStorage.getItem(`surebridge-rider-${rider.name}-${plan.id}`)
      );

      if (!selectedFamilyPeriod) return null;

      const options = {
        rate: selectedFamilyPeriod?.selectedOption?.premium_amount,
        selected_benefit: selectedFamilyPeriod?.selectedOption,
      };

      const primaryApplicant = {
        applicant_id: 1,
        ...options,
      };

      const spouseApplicant = spouse
        ? {
            applicant_id: 2,
            ...options,
          }
        : null;

      const dependentsApplicants = dependents
        ? dependents.map((_, ind) => ({
            applicant_id: ind + 3,
            ...options,
          }))
        : null;

      return {
        name: rider?.name,
        plan_code: rider?.plan_code,
        rate_type: rider?.rate_type,
        rider_type: rider?.rider_type,
        applicants: [
          primaryApplicant,
          spouseApplicant,
          ...dependentsApplicants,
        ].filter((applicant) => applicant),
      };
    };

    const handleFamilyRidersWithPeriod = (rider) => {
      const selectedFamilyPeriod = JSON.parse(
        localStorage.getItem(`surebridge-rider-${rider.name}-${plan.id}`)
      );

      if (!selectedFamilyPeriod) return null;

      const options = {
        rate: selectedFamilyPeriod?.selectedPeriod?.premium_amount,
        selected_benefit: {
          ...selectedFamilyPeriod?.selectedPeriod,
          benefit_value: selectedFamilyPeriod?.selectedOption.benefit_value,
        },
      };

      const primaryApplicant = {
        applicant_id: 1,
        ...options,
      };

      const spouseApplicant = spouse
        ? {
            applicant_id: 2,
            ...options,
          }
        : null;

      const dependentsApplicants = dependents
        ? dependents.map((_, ind) => ({
            applicant_id: ind + 3,
            ...options,
          }))
        : null;

      return {
        name: rider?.name,
        plan_code: rider?.plan_code,
        rate_type: rider?.rate_type,
        rider_type: rider?.rider_type,
        applicants: [
          primaryApplicant,
          spouseApplicant,
          ...dependentsApplicants,
        ].filter((applicant) => applicant),
      };
    };

    const handleFamilyRiders = (rider) => {
      const selectedRider = JSON.parse(
        localStorage.getItem(`surebridge-rider-${rider.name}-${plan.id}`)
      );

      if (!selectedRider || selectedRider === 0) return null;

      return {
        name: rider?.name,
        plan_code: rider?.plan_code,
        rate_type: rider?.rate_type,
        rider_type: rider?.rider_type,
        premium: Number(selectedRider),
      };
    };

    return plan?.general_info?.optional_riders
      ?.map((rider) => {
        if (
          rider?.is_requote_required &&
          rider?.benefit_level_period_type === null
        ) {
          return handleFamilyRidersRequote(rider);
        }
        if (rider?.rate_type === "INDIVIDUAL") {
          return handleIndividualRiders(rider);
        }
        if (
          rider?.rate_type === "FAMILY" &&
          rider?.benefit_level_period_type === "WITH_PERIOD"
        ) {
          return handleFamilyRidersWithPeriod(rider);
        }
        if (rider?.rate_type === "FAMILY") {
          return handleFamilyRiders(rider);
        }
      })
      .filter((rider) => rider !== null);
  };

  const handleEnroll = async () => {
    setIsEnrolling(true);

    try {
      const resp = await axios({
        method: "post",
        url: `${props.baseUrl}/v1/enrollment/sure-bridge`,
        data: {
          quote_id: params.quote_id,
          location: {
            state: params.state,
            zip_code: params.zip_code,
            county: decodeURIComponent(params.county),
          },
          primary_applicant: {
            id: 1,
            date_of_birth:
              typeof props.date_of_birth === "string"
                ? props.date_of_birth
                : props.date_of_birth.toDate().toISOString().split("T")[0],
            age: params.age,
            gender: params.gender,
            uses_tobacco: params.uses_tobacco,
            first_name: params.firstName,
            last_name: params.lastName,
            email: params.email,
            phone: params.phone,
          },
          spouse: spouse
            ? {
                id: 2,
                age: props.spouse.age,
                date_of_birth:
                  typeof props.spouse.date_of_birth === "string"
                    ? props.spouse.date_of_birth
                    : props.spouse.date_of_birth
                        .toDate()
                        .toISOString()
                        .split("T")[0],
                gender: props.spouse.gender,
                uses_tobacco: props.spouse.uses_tobacco,
              }
            : null,
          dependents: props.applicants.dependents
            ? props.applicants.dependents.map((dependent, ind) => ({
                id: ind + 3,
                age: dependent.age,
                date_of_birth:
                  typeof dependent.date_of_birth === "string"
                    ? dependent.date_of_birth
                    : dependent.date_of_birth
                        .toDate()
                        .toISOString()
                        .split("T")[0],
                gender: dependent.gender,
                uses_tobacco: dependent.uses_tobacco,
              }))
            : null,
          agent: params.agentData,
          selected_plans: parsePlansToEnroll(),
          fees: {
            ONE_TIME: {
              total: oneTimeFees.reduce((acc, fee) => acc + fee.value, 0),
              fees_details: oneTimeFees,
            },
            MONTHLY: {
              total: monthlyFees.reduce((acc, fee) => acc + fee?.value, 0),
              fees_details: monthlyFees,
            },
          },
        },
        headers: {
          "Apollo-Quotes-Source": props.agentMetadata.id,
        },
      });

      window.localStorage.setItem(
        "enrollment-success-ids",
        JSON.stringify(plans.reduce((acc, plan) => [...acc, plan.id], []))
      );

      clearLocalStorage();

      setTimeout(() => {
        window.localStorage.removeItem("enrollment-success-ids");
      }, 1000);

      setEnrollmentLink(resp.data.enrollment_url);

      Toastify({
        text: `Your application has been started, proceed to Surebridge to finish your enrollment`,
        duration: 20000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#4a934a",
        stopOnFocus: true,
        className: "notification",
      }).showToast();
    } catch (err) {
      console.log(err);
      Toastify({
        text: "We are sorry, we couldn't complete your application, please try again in a few minutes.",
        duration: 10000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
      }).showToast();
    }

    setIsEnrolling(false);
  };

  const isValid = () => {
    let primaryApplicantInvalid = false;
    let spouseInvalid = false;
    let dependentsInvalid = false;

    if (spouse) {
      spouseInvalid = !props.spouse.date_of_birth;
    }

    if (dependents) {
      dependentsInvalid = !dependents.every((dependent) => {
        return dependent.date_of_birth;
      });
    }

    primaryApplicantInvalid = !props.date_of_birth;

    return !primaryApplicantInvalid && !spouseInvalid && !dependentsInvalid;
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#89D8F0"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  disabled
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  value={
                    typeof props.date_of_birth === "object"
                      ? props.date_of_birth
                      : typeof props.date_of_birth === "string"
                      ? moment(props.date_of_birth)
                      : null
                  }
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
            </Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#89D8F0"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    disabled
                    value={
                      typeof props.spouse.date_of_birth === "object"
                        ? props.spouse.date_of_birth
                        : typeof props.spouse.date_of_birth === "string"
                        ? moment(props.spouse.date_of_birth)
                        : null
                    }
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
              </Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#89D8F0"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
                <Container className={css.dependentInfoContainer}>
                  <span>
                    Age <strong>{dep.age}</strong>
                  </span>
                  <span>
                    Gender{" "}
                    <Icon
                      icon={`gender-${dep.gender.toLowerCase()}`}
                      className={css.dependentGender}
                    />
                  </span>
                </Container>
              </Container>
              <Container>
                <Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabled
                      value={
                        typeof dependents[ind].date_of_birth === "object"
                          ? dependents[ind].date_of_birth
                          : typeof dependents[ind].date_of_birth === "string"
                          ? moment(dependents[ind].date_of_birth)
                          : null
                      }
                      before={"Date Of Birth"}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>

      <Container className={css.priceDisclaimer}>
        *The monthly payment is consistent with the selected date and term
        length. <br />
        By setting another date the value will be changed automatically
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            disabled
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          {enrollmentLink ? (
            <Button
              style={{ minWidth: "200px" }}
              // disabled={isValid()}
              onClick={() => {
                window.open(enrollmentLink, "_blank");
              }}
              type="primary"
              shape="round"
            >
              Go to Surebridge platform
            </Button>
          ) : (
            <Button
              disabled={!isValid() || isEnrolling}
              onClick={() => {
                handleEnroll();
              }}
              type="primary"
              shape="round"
            >
              Enroll
            </Button>
          )}
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
  specialRates,
  quote_id,
  ...rest
}) => {
  return {
    agentMetadata,
    quote_id,
    baseUrl,
    enrollment,
    applicants,
    ...enrollment.nationalGeneral.additionalInfo,
    date_of_birth: enrollment.nationalGeneral.additionalInfo.date_of_birth
      ? enrollment.nationalGeneral.additionalInfo.date_of_birth
      : applicants.date_of_birth,
    spouse:
      Object.keys(enrollment.nationalGeneral.additionalInfo.spouse).length > 0
        ? enrollment.nationalGeneral.additionalInfo.spouse
        : applicants.spouse,
    ...rest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartValues: (payload) => {
      dispatch({ type: `UPDATE_CART_VALUES`, payload });
    },
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
